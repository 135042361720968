const requiredFiles = function (node) {
  if (!node.value) {
    return false
  }
  if (Array.isArray(node.value)){
    return node.value.length > 0
  }
  return false
}

export default requiredFiles
