import type {AppRouter} from "../../../api/src/trpc/router";
import superjson from "superjson";
import {createTRPCNuxtClient, httpBatchLink } from "trpc-nuxt/client";
import {AccessTokenStorage} from "~/storages/accessToken";
import {parseFetchCookie} from "~/libs/cookie";
import {useAuthStore} from "~/stores/authUser";
import {loggerLink} from "@trpc/client";
import { appendResponseHeader } from 'h3'

export default defineNuxtPlugin((nuxtApp) => {
  const event = useRequestEvent();
  const runtimeConfig = useRuntimeConfig();
  const { $i18n } = nuxtApp;

  const authStore = useAuthStore();
  const storage = new AccessTokenStorage(event as any);

  const config = {
    baseURL: runtimeConfig.public.API_URL,
  };

  const headers = useRequestHeaders(['cookie']);

  const client = createTRPCNuxtClient<AppRouter>({
    transformer: superjson,
    links: [
      loggerLink({
        enabled: (opts) =>
          (process.env.NODE_ENV === 'development' &&
            typeof window !== 'undefined')
          // || (opts.direction === 'down' && opts.result instanceof Error),
      }),
      httpBatchLink({
        url: `${config.baseURL}`,
        async fetch(url, options) {

          options.headers['x-app-locale'] = $i18n.locale.value;
          options.headers['x-app-scope'] = 'crm';

          const response = await fetch(url, {...options, credentials: "include"});
          if (!response.ok) {
            const clone = response.clone();
            const clonedResponse = await clone.json();

            //TODO: check... sometimes response isn't array
            const json = clonedResponse.length > 0 ? clonedResponse[0] : clonedResponse;

            // const message = json.error.json.message;
            const code = json.error.json.data.errorCode;
            // console.log("errrrr", JSON.stringify(json.error));

            if (code === "TOKEN_INVALID") {
              authStore.setUserToken(null);
              console.log('TOKEN_INVALID error')
              // event.respondWith()
              // storage.set(accessToken);
              // event.node.res.setHeader('token','wtf')
            }
            if (code === "UNAUTHORIZED") {
              authStore.setUserToken(null);
              // event.respondWith()
              // storage.set(accessToken);
              // event.node.res.setHeader('token','wtf')
            }
            if (code === "TOKEN_EXPIRED") {
              authStore.setUserToken(null);
              const refreshUrl = `${config.baseURL}/auth.accessToken`;
              const cookie = headers.cookie || "";
              const refreshResponse = await fetch(refreshUrl, {
                method: "POST",
                body: '{"json":{}}',
                credentials: "include",
                headers: {
                  cookie,
                },
              });


              if (process.server) {
                const refreshResponseHeaders = refreshResponse.headers.get('Set-Cookie')
                const parsedCookie = parseFetchCookie(refreshResponseHeaders || '');

                const {token, refreshToken} = parsedCookie
                token && storage.setCookie('token', token);
                refreshToken && storage.setCookie('refreshToken', refreshToken, true);

                const cookies = (refreshResponse.headers.get('set-cookie') || '').split(',')
                for (const cookie of cookies) {
                  appendResponseHeader(event, 'set-cookie', cookie)
                }
                authStore.setUserToken(token)
                return await fetch(url, {
                  ...options,
                  credentials: "include",
                  headers: {
                    ...options?.headers,
                    Authorization: token!,
                  },
                });
              } else {
                const authStore = useAuthStore();

                const refreshDataCloned = refreshResponse.clone()
                const refreshData = await refreshDataCloned.json()

                const token = refreshData?.result?.data?.json?.token;
                const code = refreshData?.error?.json?.data?.errorCode;

                if (code === 'TOKEN_NOT_FOUND') {
                  const route = useRoute()
                  await authStore.logout(route.fullPath);
                  return ;
                }

                token && authStore.setUserToken(token);
                return await fetch(url, {
                  ...options,
                  credentials: "include",
                  headers: {
                    ...options?.headers,
                    Authorization: token!,
                  },
                });
              }


            }
          }

          return response;
        },
        headers() {
          const authStore = useAuthStore();
          const str = authStore.getUserToken;
          if (str) {
            return {
              ...headers,
              Authorization: str || undefined,
            };
          } else {
            return {
              ...headers,
              // Authorization: token.getAccessToken(),
            };
          }
        },
      }),
    ],
  });

  return {
    provide: {
      client,
    },
  };
});
