import {useAuthStore} from "~/stores/authUser";

export default defineNuxtRouteMiddleware(async (to) => {
  if (!to.meta.auth) {
    return;
  }

  const localePath = useLocalePath()
  const authStore = useAuthStore();


  if (!authStore.canAccess(to.meta.auth.roles)) {
    if (to.fullPath === '/'){
      return navigateTo(localePath(`/auth/login`));
    }
    if (!authStore.loggedIn) {
      return navigateTo(localePath(`/auth/login?redirect=${encodeURIComponent(to.fullPath)}`));
    }
    return navigateTo(`/403`);
  }

  if (!authStore.loggedIn) {
    return navigateTo(localePath(`/auth/login?redirect=${encodeURIComponent(to.fullPath)}`));
  }
});
