<script lang="ts" setup>

</script>

<template>
  <div class="loader">
    <img
      src="~/assets/img/loader.png"
      alt=""
    >
  </div>
</template>


<style lang="scss" scoped>
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;

  img {
    animation: loader 1.5s ease-in-out infinite;
  }

  @keyframes loader {
    0% {
      transform: rotate(0);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

</style>
