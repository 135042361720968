<script lang="ts" setup>
import {useDropZone} from "@vueuse/core";

const {t} = useI18n({
  useScope: "global"
});

const dropZoneRef = ref<HTMLDivElement>();
const fileInputRef = ref(null);
const props = defineProps({
  context: Object
});
const documents = ref(props.context._value || []);

const {isOverDropZone} = useDropZone(dropZoneRef, onDrop);

const isEmpty = computed(() => {
  return documents.value.length === 0;
});

const handleFiles = (files) => {

  for (const file of files) {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      documents.value.push({
        filename: file.name,
        file: e.target.result
      });
      props.context.node.input(documents.value);
    };
    reader.readAsDataURL(file);
  }
};

function handleInput(e) {
  const files = Array.from(e.target.files);
  handleFiles(files);
}

function onDrop(files: File[] | null) {
  handleFiles(files);
}

const removeFile = (index) => {
  documents.value.splice(index, 1);
  props.context.node.input(documents.value);
  return false;
};

// const clearFiles = () => {
//   documents.value = [];
//   props.context.node.input(documents.value);
// };
const onAddFileClick = () => {
  if (fileInputRef.value) {
    fileInputRef.value.click();
  }
};
</script>

<template>
  <div
    class="document-input"
    :class="[{ 'empty': isEmpty }, { 'over-dropzone': isOverDropZone }]"
  >
    <div
      ref="dropZoneRef"
      class="dropzone"
    >
      <div class="document-container">
        <div
          v-for="(document, index) in documents"
          :key="index"
          class="document-item"
        >
          <div v-if="document.file?.includes('image/')">
            <NuxtImg
              :src="document.file"
              alt="img"
            />
          </div>
          <div
            v-else
            class="document-item__wrapper"
          >
            <img
              class="icon"
              src="assets/img/icons/document.svg"
              alt="icon"
            >
            <span class="text">{{ document.filename }}</span>
          </div>
          <button
            class="document-item__btn"
            type="button"
            @click="removeFile(index)"
          >
            <img
              class="icon"
              src="assets/img/icons/close.svg"
              alt="close"
            >
          </button>
        </div>
      </div>
      <div class="drop-guide">
        <div class="actions">
          <!--          <FormKit-->
          <!--            v-if="!isEmpty"-->
          <!--            type="button"-->
          <!--            variant="outline"-->
          <!--            width="inline"-->
          <!--            @click="clearFiles"-->
          <!--          >-->
          <!--            {{ t("formkit.documents.clear") }}-->
          <!--          </FormKit>-->
          <FormKit
            variant="outline"
            type="button"
            width="inline"
            @click="onAddFileClick"
          >
            {{ isEmpty ? t("formkit.documents.selectFiles") : t("formkit.documents.addMore") }}
          </FormKit>
        </div>
        <div class="text">
          {{ t("formkit.documents.dropFilesHere") }}
        </div>
      </div>
    </div>

    <input
      ref="fileInputRef"
      class="real-input"
      type="file"
      multiple
      @change="handleInput"
    >
  </div>
</template>

<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.document-input {

  .dropzone {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    border-radius: 10px;
    border: 1px dashed rgba(45, 45, 134, 0.20);
    padding: 20px;
    margin: 20px;

    transition: background-color .2s, border-color .2s;

  }

  &.over-dropzone {
    .dropzone {
      background: rgba(45, 45, 134, 0.05);
      filter: blur(3px);
      border-color: #2D2D86;
    }
  }

  //&.empty {
  //  .dropzone {
  //    height: 100px;
  //  }
  //}

  .drop-guide {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .text {
      margin-top: 15px;

      font-size: 12px;
      color: #1F163B;
      line-height: 14px;
      opacity: .5;
    }
  }
}

.real-input {
  display: none;
}


.document-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(182px, auto));
  grid-gap: 10px;
}

.document-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  border-radius: 10px;
  border: 1px solid rgba(45, 45, 134, 0.20);
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 15px;
  overflow: hidden;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      font-size: 12px;
      color: #1F163B;
      line-height: 14px;
      word-break: break-word;
    }
  }

  &__btn {
    flex-shrink: 0;

    .icon {
      width: 12px;
      height: 12px;
    }
  }


  //button {
  //  width: 100%;
  //
  //  &:hover {
  //    .document-container {
  //      position: relative;
  //      opacity: 0.8;
  //
  //      &::after {
  //        content: "Remove";
  //        position: absolute;
  //        top: 0;
  //        left: 0;
  //        right: 0;
  //        bottom: 0;
  //        background: rgba(234, 36, 36, 0.42);
  //        display: flex;
  //        justify-content: center;
  //        align-items: center;
  //        color: #fff;
  //        font-size: 8px;
  //        font-weight: 500;
  //      }
  //    }
  //  }
  //}
  //
  //span {
  //  margin-top: 5px;
  //  text-overflow: ellipsis;
  //  max-width: 100%;
  //  overflow: hidden;
  //  font-size: 10px;
  //  font-weight: 500;
  //  display: inline-block;
  //}
}
</style>
