import {ru, en} from "@formkit/i18n";
import {genesisIcons} from '@formkit/icons'
import {createInput, defineFormKitConfig} from "@formkit/vue";
import OneTimePassword from "~/libs/formkit/inputs/one-time-password.vue";
import Password from "~/libs/formkit/inputs/password.vue";
import {createAutoAnimatePlugin, createLocalStoragePlugin} from "@formkit/addons";
import Dropdown from "~/libs/formkit/inputs/dropdown.vue";
import Dropdown2 from "~/libs/formkit/inputs/dropdown2.vue";
import {generateClasses} from "@formkit/themes";
import './assets/scss/indigo-ui-theme.scss'
import {button} from "~/libs/formkit/inputs/button";
import {action} from "~/libs/formkit/inputs/action";
import {checkbox} from "~/libs/formkit/inputs/checkbox";
import {radio} from "~/libs/formkit/inputs/radio";
import {textarea} from "~/libs/formkit/inputs/textarea";
import {select} from "~/libs/formkit/inputs/select";
import icons from "~/libs/formkit/icons";
import {group} from "~/libs/formkit/sections/group";
import Gallery from "~/libs/formkit/inputs/gallery.vue";
import Documents from "~/libs/formkit/inputs/documents.vue";
import Text from "~/libs/formkit/inputs/text.vue";
import Markdown from "~/libs/formkit/inputs/markdown.vue";
import {requiredAsteriskPlugin} from "~/libs/formkit/plugins/required-asterisk";
import {transformPlugin} from "./plugins/transform";
import requiredFiles from "~/libs/formkit/rules/requiredFiles";


const config = defineFormKitConfig(() => {
  const config = useRuntimeConfig()

  return {
    inputs: {
      // text: text,
      text: createInput(Text, {}),
      markdown: createInput(Markdown, {}),
      gallery: createInput(Gallery, {}),
      documents: createInput(Documents, {}),
      password: createInput(Password, {}),
      otp: createInput(OneTimePassword, {
        props: ["digits"],
      }),
      dropdown2: createInput(Dropdown2, {
        props: ["options", "searchable", "placeholder"],
      }),
      dropdown: createInput(Dropdown, {
        props: ["options", "searchable", "placeholder"],
      }),
      textarea: textarea,
      select: select,
      // submit: submit,
      action: action,
      button: button,
      submit: button,
      checkbox: checkbox,
      radio: radio,
      group: group,
    },
    config: {
      classes: generateClasses({}),
      rootClasses: (sectionKey, node) => {
        return {
          [`indigo-ui-${sectionKey}`]: true,
        };
      },
    },
    rules: {
      requiredFiles ,
    },
    messages: {
      en: {
        validation: {
          requiredFiles({ name }) {
            return `Please fill out the ${name} field.`
          }
        }
      }
    },
    plugins: [
      createAutoAnimatePlugin(),
      createLocalStoragePlugin({
        prefix: 'formkit',
        maxAge: 1000 * 60 * 60 * 24 * 31,
        debounce: 1000,
        // beforeLoad: undefined
      }),
      transformPlugin,
      requiredAsteriskPlugin,
    ],
    icons: {
      ...genesisIcons,
      ...icons,
    },
    locales: {
      ru,
      en,
    },
    locale: "en",
  }
})

export default config
