<script lang="ts" setup>
import {handleUploadImage} from "~/libs/markdown/editor";
import {leftToolbar, rightToolbar} from "~/libs/markdown/toolbar";
import {VMdEditor} from "~/libs/markdown/editor";

const props = defineProps({
  context: Object,
})

const onInput = (value) => {
  props.context.node.input(value)
}
</script>

<template>
  <div class="md-input">
    <v-md-editor
      :disabled-menus="[]"
      :left-toolbar="leftToolbar"
      :right-toolbar="rightToolbar"
      :value="props.context._value"
      height="400px"
      @change="onInput"
      @upload-image="handleUploadImage"
    />
  </div>
</template>

<style lang="scss">
[data-type="markdown"] {
  @import "../assets/scss/errors";

  &[data-disabled] {
    opacity: 30%;
  }

  width: 100%;
  margin-bottom: 20px;

  .indigo-ui-inner {
    width: 100%;
    box-shadow: none;
    border: 1px solid rgba(45, 45, 134, 0.2);
    border-radius: 10px;
    overflow: hidden;

    [readonly] {
      cursor: default;
    }

    .indigo-ui-input {
      width: 100%;
    }
  }
}
</style>
