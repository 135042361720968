<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CarouselItems>(), {
    //TODO: remove dependency on slides
    slides: [],
  })
import { toRefs as __MACROS_toRefs } from "vue";


interface CarouselItems {
  slides: string[]
}

const { slides } = __MACROS_toRefs(__MACROS_props)

const emit = defineEmits(['prev', 'next'])
</script>
<template>
  <div class="carousel-controls">
    <div class="image-wrapper">
      <slot />
    </div>
    <!-- TODO: refactor click layout -->
    <!-- <div class="controls-wrapper">
    </div> -->
    <button
      v-if="slides.length"
      class="btn-arrow prev"
      @click="emit('prev')"
    >
      <svg
        width="15"
        height="27"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 26L2 13.5L14 1"
          stroke="#2D2D86"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
    <button
      v-if="slides.length"
      class="btn-arrow next"
      @click="emit('next')"
    >
      <svg
        width="15"
        height="27"
        viewBox="0 0 15 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 26L13 13.5L1 1"
          stroke="#2D2D86"
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.image-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
}

.btn-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.6);
  transition: background-color .3s;

  &.prev {
    border-radius: 0 3px 3px 0;
    left: 0;
  }

  &.next {
    border-radius: 3px 0px 0px 3px;
    right: 0;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
}
</style>
