<script lang="ts" setup>
const props = defineProps({
  context: Object,
})

function handleInput(e) {
  props.context.node.input(e.target.value)
}
const onIconClick = (type) => {
  props.context.handlers.iconClick(type)()
}
</script>

<template>
  <div class="text-input">
    <input
      :id="props.context.id"
      :name="props.context.node.name"
      :class="[props.context.type]"
      :type="props.context.type"
      :placeholder="props.context.attrs.placeholder"
      :disabled="props.context.disabled"
      :readonly="props.context.attrs.readonly"
      :value="props.context._value"
      @input="handleInput"
      @blur="props.context.handlers.blur"
    >
  </div>
</template>

<style lang="scss">


[data-type="text"], [data-type="date"] {
  @import "../assets/scss/errors";

  &[data-disabled] {
    opacity: 30%;
  }

  width: 100%;
  margin-bottom: 20px;

  .indigo-ui-inner {
    width: 100%;
    box-shadow: none;
    border: 1px solid rgba(45, 45, 134, 0.2);
    border-radius: 10px;
    overflow: hidden;

    [readonly] {
      cursor: default;
    }
    input {
      appearance: none;
      width: 100%;
      height: 36px;
      background: transparent;
      padding: 0 15px;
      &.date {
        padding: 0px;
      }

      border: none;
      color: var(--text-color-1);

      &::placeholder {
        color: rgba(45, 45, 134, 0.2);
      }
    }

    .indigo-ui-input {
      width: 100%;


    }

  }

}

[data-suffix-icon="true"]{
  .indigo-ui-inner {
    display: flex;
    .indigo-ui-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  .indigo-ui-inner input{
    padding-left: 5px;
    padding-right: 5px;
  }

}

[data-suffix-icon-click="true"]{
  .indigo-ui-icon {
    cursor: pointer;
  }
}
</style>
