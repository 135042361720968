import type { ProviderGetImage } from '@nuxt/image'
import { createOperationsGenerator } from '#image'
import { joinURL, encodePath, encodeParam, parseURL } from 'ufo'

const operationsGenerator = createOperationsGenerator({
    keyMap: {
        format: 'f',
        fit: 'fit',
        width: 'w',
        height: 'h',
        resize: 's',
        quality: 'q',
        background: 'b'
    },
    joinWith: '&',
    formatter: (key:string, val:string) => encodeParam(key) + '_' + encodeParam(val)
})
export const getImage: ProviderGetImage = (
    src,
    { modifiers = {}, baseURL } = {}
) => {
    if (!baseURL) {
        // also support runtime config
        baseURL = useRuntimeConfig().public.IPX_SERVICE_URL
    }

    const parsedUrl = parseURL(src)

    let isAbsolute = false
    if (!parsedUrl.host) {
        isAbsolute = true
    }

    const params = operationsGenerator(modifiers) || '_'

    const newUrl = joinURL(baseURL, params, isAbsolute ? encodePath(`${useRuntimeConfig().public.CRM_URL}${src}`) : encodePath(src) )
    return {
        url: newUrl
    }
}
