<script lang="ts" setup>
const props = defineProps({
  view: {
    type: Object,
    default: () => ({}),
  },
  short: {
    type: Boolean,
    default: () => false,
  },
  noTotals: {
    type: Boolean,
    default: () => false,
  },
});
const { t } = useI18n({
  useScope: "global",
});

const showProp = (prop) => {
  if (![ 'amountPaid', 'balance' ].includes(prop) && !props.short) {
    return true
  }

  if ([ 'amountPaid', 'balance' ].includes(prop) && props.noTotals) {
    return false
  }

  return props.view[prop] !== 0;
};
</script>

<template>
  <div class="financial-info-view">
    <PageCarInfoItem
      v-if="showProp('auctionPrice')"
      :title="t('car.financialInfo.auctionPrice')"
    >
      <FormatCurrency :value="view.auctionPrice" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('shipmentPrice')"
      :title="t('car.financialInfo.shipmentPrice')"
    >
      <FormatCurrency :value="view.shipmentPrice" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('cuttingPrice')"
      :title="t('car.financialInfo.cuttingPrice')"
    >
      <FormatCurrency :value="view.cuttingPrice" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('damageCompensation')"
      :title="t('car.financialInfo.damageCompensation')"
    >
      <FormatCurrency :value="view.damageCompensation" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('auctionStorage')"
      :title="t('car.financialInfo.auctionStorage')"
    >
      <FormatCurrency :value="view.auctionStorage" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('auctionSublot')"
      :title="t('car.financialInfo.auctionSublot')"
    >
      <FormatCurrency :value="view.auctionSublot" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('auctionDocumentCorrectionFee')"
      :title="t('car.financialInfo.auctionDocumentCorrectionFee')"
    >
      <FormatCurrency :value="view.auctionDocumentCorrectionFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('issuedLoanAmount')"
      :title="t('car.financialInfo.issuedLoanAmount')"
    >
      <FormatCurrency :value="view.issuedLoanAmount" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('percent')"
      :title="t('car.financialInfo.percent')"
    >
      <FormatCurrency :value="view.percent" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('disbursementFee')"
      :title="t('car.financialInfo.disbursementFee')"
    >
      <FormatCurrency :value="view.disbursementFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('amountCarryover')"
      :title="t('car.financialInfo.amountCarryover')"
    >
      <span :class="[{ positive: view.amountCarryover > 0, negative: view.amountCarryover < 0 }]"><FormatCurrency :value="view.amountCarryover" /></span>
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('auctionBuyerSaleFee')"
      :title="t('car.financialInfo.auctionBuyerSaleFee')"
    >
      <FormatCurrency :value="view.auctionBuyerSaleFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('closeAuctionFee')"
      :title="t('car.financialInfo.closeAuctionFee')"
    >
      <FormatCurrency :value="view.closeAuctionFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('domesticFee')"
      :title="t('car.financialInfo.domesticFee')"
    >
      <FormatCurrency :value="view.domesticFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('detailsPackingFee')"
      :title="t('car.financialInfo.detailsPackingFee')"
    >
      <FormatCurrency :value="view.detailsPackingFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('receiverChangeFee')"
      :title="t('car.financialInfo.receiverChangeFee')"
    >
      <FormatCurrency :value="view.receiverChangeFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('hybridOrElectricCarFee')"
      :title="t('car.financialInfo.hybridOrElectricCarFee')"
    >
      <FormatCurrency :value="view.hybridOrElectricCarFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('engineAndTransmissionInsuranceFee')"
      :title="t('car.financialInfo.engineAndTransmissionInsuranceFee')"
    >
      <FormatCurrency :value="view.engineAndTransmissionInsuranceFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('antiMoistureBagFee')"
      :title="t('car.financialInfo.antiMoistureBagFee')"
    >
      <FormatCurrency :value="view.antiMoistureBagFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('saleTax')"
      :title="t('car.financialInfo.saleTax')"
    >
      <FormatCurrency :value="view.saleTax" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('releaseFee')"
      :title="t('car.financialInfo.releaseFee')"
    >
      <FormatCurrency :value="view.releaseFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('latePaymentFee')"
      :title="t('car.financialInfo.latePaymentFee')"
    >
      <FormatCurrency :value="view.latePaymentFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('paidDealerComission')"
      :title="t('car.financialInfo.paidDealerComission')"
    >
      <FormatCurrency :value="view.paidDealerComission" />
    </PageCarInfoItem>

    <PageCarInfoItem
      v-if="showProp('nonStandartTransportationFee')"
      :title="t('car.financialInfo.nonStandartTransportationFee')"
    >
      <FormatCurrency :value="view.nonStandartTransportationFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('insuranceFee')"
      :title="t('car.financialInfo.insuranceFee')"
    >
      <FormatCurrency :value="view.insuranceFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('extraPhotoFee')"
      :title="t('car.financialInfo.extraPhotoFee')"
    >
      <FormatCurrency :value="view.extraPhotoFee" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('amountSale')"
      :title="t('car.financialInfo.amountSale')"
    >
      <FormatCurrency :value="view.amountSale" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('amountCarryOverAutomated')"
      :title="t('car.financialInfo.amountCarryOverAutomated')"
    >
      <FormatCurrency :value="view.amountCarryOverAutomated" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('amountFromAccountFunds')"
      :title="t('car.financialInfo.amountFromAccountFunds')"
    >
      <FormatCurrency :value="view.amountFromAccountFunds" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('balance')"
      :class="[{ positive: view.balance > 0, negative: view.balance < 0 }]"
      :title="t('car.financialInfo.balance')"
    >
      <FormatCurrency :value="view.balance" />
    </PageCarInfoItem>
    <PageCarInfoItem
      v-if="showProp('amountPaid')"
      class="sum"
      :title="t('car.financialInfo.amountPaid')"
    >
      <FormatCurrency :value="view.amountPaid" />
    </PageCarInfoItem>
  </div>
</template>

<style lang="scss" scoped>
.financial-info-view {
  @media only screen and (max-width: $laptopS) {
    padding: 15px;
  }

  > .title {
    font-weight: 700;
    margin-bottom: 20px;
  }

  .item {
    margin-bottom: 12px;
  }

  .sum {
    font-weight: 600;

    :deep(.item-title) {
      font-size: 14px;
    }
  }

  .positive {
    color: var(--green-color);
  }

  .negative {
    color: var(--secondary-color);
  }
}
</style>
