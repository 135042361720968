export default defineAppConfig({
  theme: {
    primaryColor: "#ababab",
  },
  debug: false,
  preferences: {
    carsSearchByVin: false as boolean,
    withManager: false as boolean,
  }
});

declare module "nuxt/schema" {
  interface AppConfigInput {
    /** Theme configuration */
    theme?: {
      /** Primary app color */
      primaryColor?: string;
    };
    debug?: boolean;
    preferences: {
      carsSearchByVin: boolean;
      withManager: boolean;
    }
  }
}

// It is always important to ensure you import/export something when augmenting a type
export {};
