import {useAuthStore} from "~/stores/authUser";
import {parseCookie} from "~/libs/cookie";

export default defineNuxtPlugin(async nuxtApp => {
  // Doing something with nuxtApp
  // console.log('10. auth. nuxtApp', Object.keys(nuxtApp))
  const authStore = useAuthStore();
  const cookie = useSsrCookie()
  const {token} = parseCookie(cookie)
  authStore.setUserToken(token);

  try {
    if (authStore.canLoadUser) {
      await authStore.loadUser();
    }
  } catch (e) {
    console.log('cant load useer',e)
  }
})
