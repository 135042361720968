import {FormKitTypeDefinition} from "@formkit/core";
import {
  wrapper,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  submitInput,
  buttonLabel,
  localize,
  ignores,
  $attrs, buttonInput,
} from "@formkit/inputs";
import {outerSection} from "~/libs/formkit/utils";
import './button.scss'
import './submit.scss'

/**
 * Input definition for a button.
 * @public
 */

const buttonOuter = outerSection({
  attrs: {
    'data-variant': "$variant || 'primary'",
    'data-color': "$color || 'default'",
    'data-size': "$size || 'medium'",
    'data-width': "$width || 'normal'"
  }
})
export const button: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: buttonOuter(
    messages(message("$message.value")),
    wrapper(
      buttonInput(
        icon('prefix'),
        prefix(),
        buttonLabel('$label || $ui.submit.value || ""'),
        suffix(),
        icon('suffix')
      )
    ),
    help("$help")
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: "input",
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: "button",
  /**
   * An array of extra props to accept for this input.
   */
  props: ["variant", "color", "size", "width"],
  /**
   * Additional features that should be added to your input
   */
  features: [localize("button"), ignores],
};
