import '@kangc/v-md-editor/lib/style/base-editor.css';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import '@kangc/v-md-editor/es/plugins/tip/tip.css';
import '@kangc/v-md-editor/es/plugins/todo-list/todo-list.css';

import hljs from 'highlight.js';

import Editor from '@kangc/v-md-editor';

import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import enLocale from '@kangc/v-md-editor/lib/lang/en-US';

import createTipPlugin from '@kangc/v-md-editor/es/plugins/tip';
import createTodoListPlugin from '@kangc/v-md-editor/es/plugins/todo-list';
import createAlignPlugin from '@kangc/v-md-editor/es/plugins/align';

import extend from "~/libs/markdown/extend";

Editor.lang.use('en-US', enLocale);
Editor.use(githubTheme, {
  Hljs: hljs,
  extend
});

Editor.use(createTipPlugin());
Editor.use(createTodoListPlugin());
Editor.use(createAlignPlugin());

export const toBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export const handleUploadImage = async (event, insertImage, files) => {
  const client = useClient()

  for (const file of files) {
    const {url} = await client.file.upload.mutate({
      file: await toBase64(file),
      filename: file.name,
    })

    insertImage({
      url,
      desc: '',
      // width: 'auto',
      // height: 'auto',
    });
  }
}

export const VMdPreview = Editor.Preview
export const VMdEditor = Editor
