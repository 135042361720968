import reset from './assets/icons/reset.svg?raw'
import damage from './assets/icons/damage.svg?raw'
import service from './assets/icons/service.svg?raw'
import transfer from './assets/icons/transfer.svg?raw'
import checkbox from './assets/icons/checkbox.svg?raw'
import checkboxChecked from './assets/icons/checkboxChecked.svg?raw'
import radio from './assets/icons/radio.svg?raw'
import radioChecked from './assets/icons/radioChecked.svg?raw'
import invoice from './assets/icons/invoice.svg?raw'
import add from './assets/icons/new.svg?raw'
import sort from './assets/icons/sort.svg?raw'
import download from './assets/icons/download.svg?raw'
import badge from './assets/icons/badge.svg?raw'
import close from './assets/icons/close.svg?raw'
import cross from './assets/icons/cross.svg?raw'
import filter from './assets/icons/filter.svg?raw'
import filter_black from './assets/icons/filter-black.svg?raw'

export default {
  reset,
  damage,
  service,
  transfer,
  radio,
  radioChecked,
  checkbox,
  checkboxChecked,
  invoice,
  sort,
  add,
  download,
  badge,
  close,
  filter,
  filter_black,
  cross
}
