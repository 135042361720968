<script lang="ts" setup>
import {useDropZone} from '@vueuse/core'

const dropZoneRef = ref<HTMLDivElement>()
const fileInputRef = ref(null)
const props = defineProps({
  context: Object,
})
const photos = ref(props.context._value || [])

const {isOverDropZone} = useDropZone(dropZoneRef, onDrop)

const isEmpty = computed(() => {
  return photos.value.length === 0
})


const handleFiles = (files) => {

  for (const file of files) {
    const reader = new FileReader();
    reader.onloadend = function (e) {
      photos.value.push({
        filename: file.name,
        file: e.target.result,
      });
      props.context.node.input(photos.value)
    };
    reader.readAsDataURL(file);
  }
}

function handleInput(e) {
  const files = Array.from(e.target.files);
  handleFiles(files)
}

function onDrop(files: File[] | null) {
  handleFiles(files)
}

const removePhoto = (index) => {
  photos.value.splice(index, 1);
  props.context.node.input(photos.value)
}

const clearFiles = () => {
  photos.value = []
  props.context.node.input(photos.value)
}
const onAddFileClick = () => {
  if (fileInputRef.value) {
    fileInputRef.value.click()
  }
}
</script>

<template>
  <div
    class="gallery-input"
    :class="[{ 'empty': isEmpty }, { 'over-dropzone': isOverDropZone }]"
  >
    <AppCard style="margin:2px;margin-bottom:20px">
      <div
        ref="dropZoneRef"
        class="dropzone"
      >
        <div class="gallery-container">
          <div
            v-for="(photo, index) in photos"
            :key="index"
            class="gallery-item"
          >
            <button @click="removePhoto(index)">
              <div class="image-container">
                <img
                  :src="photo.file"
                  alt=""
                >
              </div>

              <span>{{ photo.filename }}</span>
            </button>
          </div>
        </div>
        <div class="drop-guide">
          <div>Drop files here</div>
          <div class="or">
            or
          </div>
          <div class="actions">
            <FormKit
              v-if="!isEmpty"
              type="button"
              variant="outline"
              width="inline"
              @click="clearFiles"
            >
              Clear
            </FormKit>
            <FormKit
              type="button"
              width="inline"
              @click="onAddFileClick"
            >
              {{ isEmpty ? 'Select files' : 'Add more' }}
            </FormKit>
          </div>
        </div>
      </div>

      <input
        ref="fileInputRef"
        class="real-input"
        type="file"
        multiple
        @change="handleInput"
      >
    </AppCard>
  </div>
</template>

<style lang="scss" scoped>
.actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.gallery-input {
  .or {

  }

  .dropzone {
    width: 100%;
    border: 1px dashed transparent;
  }

  &.over-dropzone {
    .dropzone {
      border: 1px dashed var(--primary-color);
    }
  }

  &.empty {
    .dropzone {
      height: 100px;
    }
  }

  .drop-guide {
    margin-top: 15px;
    text-align: center;

    > div {
      margin-bottom: 5px;
    }
  }
}

.real-input {
  display: none;
}


.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  grid-gap: 10px;
}

.gallery-item {
  margin-right: 10px;
  margin-bottom: 10px;
  overflow: hidden;

  button {
    width: 100%;

    &:hover {
      .image-container {
        position: relative;
        opacity: 0.8;

        &::after {
          content: "Remove";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(234, 36, 36, 0.42);
          display: flex;
          justify-content: center;
          align-items: center;
          color: #fff;
          font-size: 8px;
          font-weight: 500;
        }
      }
    }
  }

  img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  span {
    margin-top: 5px;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    font-size: 10px;
    font-weight: 500;
    display: inline-block;
  }
}

[data-type="gallery"] {
  margin-bottom: 15px;
}

[data-type="text"], [data-type="date"] {
  @import "../assets/scss/errors";

  &[data-disabled] {
    opacity: 30%;
  }

  width: 100%;
  margin-bottom: 20px;

  .indigo-ui-inner {
    width: 100%;
    box-shadow: none;
    border: 1px solid rgba(45, 45, 134, 0.2);
    border-radius: 10px;
    overflow: hidden;

    input {
      appearance: none;
      width: 100%;
      height: 36px;
      background: transparent;
      padding: 0 15px;

      &.date {
        padding: 0px;
      }

      border: none;
      color: var(--text-color-1);

      &::placeholder {
        color: rgba(45, 45, 134, 0.2);
      }
    }

    .indigo-ui-input {
      width: 100%;


    }

  }

}

</style>
