import {undefine} from "@formkit/utils";

export function transformPlugin (node) {
  node.addProps(['transform'])

  const transformEnabled = undefine(node.props.transform)

  if (transformEnabled) {
    const options = node.props.transform.split('|')

    node.hook.input((value, next) => {
      if (options.includes('uppercase')) {
        value = value ? `${value.toUpperCase()}` : value
      }

      return next(value)
    })
  }
}
