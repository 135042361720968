import {useAuthStore} from "../stores/authUser";
import {useInboxReadBlockingModal} from "~/composables/modal";

export default defineNuxtPlugin(() => {
  addRouteMiddleware(async (to, from) => {
    if (process.server) {
      return;
    }

    const authStore = useAuthStore();

    if (to.path.indexOf('/auth') !== -1 ) {
      return;
    }

    if (!authStore.user) {
      return;
    }

    //TODO: check better way to block ui
    await useInboxReadBlockingModal(undefined, {})

  })
})
