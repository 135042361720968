<script lang="ts">
const modalName = "CarTerms"
</script>
<script setup lang="ts">
import { useModalsStore } from "../../stores/modalsStore";
import { useAuthStore } from "../../stores/authUser";

const modalsStore = useModalsStore();

const route = useRoute();

const { t } = useI18n({
  useScope: "global"
});

const submitting = ref(false);

const carTermsModal = reactive({
  loading: true,
  error: null,
  result: null
});


const onFormLoad = async () => {
  try {
    carTermsModal.loading = true;
    const client = useClient();
    const result = await client.car.termsPreview.mutate({
      carId: +route.params.id
    });
    carTermsModal.result = result;
    carTermsModal.loading = false;
  } catch (e) {
    console.error(e);
    carTermsModal.loading = false;
    carTermsModal.error = e;
  }
};
watch(() => modalsStore.activeModal, async (value) => {
  if (value === modalName) {
    await onFormLoad();
  }
});

const onSubmit = async (formData, node) => {
  try {
    node.setErrors(
      []
    );
    submitting.value = true;
    const client = useClient();
    await client.car.termsAccept.mutate({
      carId: +route.params.id,
    });
    submitting.value = false;
    await modalsStore.closeModal(modalName);
    return;
  } catch (e) {
    node.setErrors(
      [e.message]
    );
    submitting.value = false;
    return false;
  }
};

</script>

<template>
  <AppModal
    v-slot="{ scroll }"
    :title="t('modal.carTerms.title')"
    :closable="false"
    :open="modalsStore.activeModal === modalName"
    width="931px"
    @close="modalsStore.closeModal(modalName)"
  >
    <template v-if="carTermsModal.loading">
      <UiLoader style="width: 100%; height: 400px" />
    </template>
    <template v-else-if="carTermsModal.error">
      <div>
        <div class="error">
          {{ carTermsModal.error }}
        </div>
        <FormKit
          type="submit"
          :label="t('modal.carTerms.logout')"
          @click="abortWithLogout"
        />
      </div>
    </template>
    <template v-else>
      <div class="car-terms">
        <div class="terms">
          <ClientOnly>
            <AppPdfPreview :link="carTermsModal.result?.url" />
          </ClientOnly>
        </div>
        <div class="footer">
          <FormKit
            id="car-terms"
            v-slot="{ value }"
            type="form"
            :actions="false"
            @submit="onSubmit"
          >
            <FormKit
              :disabled="!scroll.arrivedState.bottom"
              name="terms"
              outer-class="checkbox"
              type="checkbox"
              :label="t('modal.carTerms.agreement')"
            />
            <FormKit
              type="group"
              variant="inline"
            >
              <FormKit
                :disabled="!value?.terms || submitting"
                type="submit"
                :label="t('modal.carTerms.submit')"
              />
            </FormKit>
          </FormKit>
        </div>
      </div>
    </template>
  </AppModal>
</template>

<style lang="scss" scoped>
.car-terms {
  position: relative;
  height: 50vh;

  .footer {
    position: sticky;
    bottom: 0;
    z-index: 1;
    width: 100%;
    background-color: #fff;
    padding: 10px 15px;
  }
}
</style>
