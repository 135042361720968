<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<NoTitleModalProps>(), {
  clickToClose: true,
  escToClose: true,
  width: '420px',
})
import { toRefs as __MACROS_toRefs } from "vue";
import {VueFinalModal} from 'vue-final-modal'


interface NoTitleModalProps {
  width?: string
  maxHeight?: string
  clickToClose?: boolean,
  escToClose?: boolean,
}

const {clickToClose, escToClose, maxHeight} = __MACROS_toRefs(__MACROS_props)

const {t} = useI18n({
  useScope: 'global',
})

const scrollContainer = ref(null);
const scroll = useScroll(scrollContainer)

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const onInnerClick = (e: MouseEvent) => {
  if (!clickToClose.value) {
    return
  }
  emit('update:modelValue', false)
}
</script>

<template>
  <VueFinalModal
    class="modal-container-v2"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
    :click-to-close="clickToClose"
    :esc-to-close="escToClose"
  >
    <div
      class="inner-container"
      @click.self="onInnerClick"
    >
      <div class="modal-test">
        <div
          class="modal-container-v2-content"
          :style="{width: width}"
        >
          <div class="modal-body">
            <AppScrollContainer
              ref="scrollContainer"
              class="scrollContainer"
              :style="[{maxHeight}]"
            >
              <slot :scroll="scroll" />
            </AppScrollContainer>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<style lang="scss" scoped>
.modal-container-v2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container-v2-content {
  width: 420px;
  max-width: 100%;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-body {
  padding: 20px 20px;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container-v2,
.modal-leave-active .modal-container-v2 {
  transform: scale(1.1);
}

:deep(*) {
  .ps {
    max-height: 520px !important;

    &.ps--active-y {
      padding-right: 15px;
      margin-right: -15px;
    }
  }

  .arrow {
    width: 16px;
    height: 14px;
    margin: 25px auto 5px;
    transform: rotate(90deg);
  }

  .indigo-ui-outer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item-label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .two-columns {
    display: flex;
    max-width: 100%;
    overflow: hidden;

    .indigo-ui-outer {
      margin-right: 20px;

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
