<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<ManagerContactInfoProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";



const {t} = useI18n({
  useScope: 'global'
})

interface ManagerContactInfoProps {
  manager: object
}

const {manager} = __MACROS_toRefs(__MACROS_props);

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const client = useClient()
const managerTest = await client.user.manager.query()

const managerData = reactive({
  name: managerTest.name,
  email: managerTest.email,
  phone: managerTest.mobilePhone,
  address: managerTest.office,
  avatar: managerTest.avatar
})

const whatsappLink = () => {
  return `https://wa.me/${managerData.phone}`;
}
const viberLink = () => {
  return `viber://chat?number=${managerData.phone}`;
}
const callNow = () => {
  return `tel:${managerData.phone}`;
}
</script>

<template>
  <div class="manager-contact-info">
    <div class="manager-contact-info__content">
      <div class="contact-card">
        <div class="contact-header">
          <div class="contact-logo">
            <img
              v-if="managerData?.avatar"
              :src="managerData.avatar"
              :alt="managerData.name"
            >
          </div>

          <div class="contact-info">
            <h2>{{ managerData.name }}</h2>

            <div
              v-if="managerData?.address"
              class="contact-address"
            >
              <p>{{ managerData.address }}</p>
            </div>

            <div>
              <a
                v-if="managerData?.email"
                class="email"
                :href="`mailto:${managerData.email}`"
              >
                {{ managerData.email }}
              </a>
            </div>
            <div>
              <a
                v-if="managerData?.phone"
                class="phone"
                :href="callNow()"
              >
                {{ managerData.phone }}
              </a>
            </div>
          </div>
        </div>
        <div class="contact-actions">
          <div class="social-icons">
            <a
              :href="callNow()"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./assets/call2.svg"
                alt="WhatsApp"
              >
            </a>
            <a
              :href="whatsappLink()"
              class="whatsapp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./assets/whatsapp.svg"
                alt="WhatsApp"
              >
            </a>
            <a
              :href="viberLink()"
              class="viber"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="./assets/viber.svg"
                alt="Viber"
              >
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.manager-contact-info {
  &__content {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
}


.contact-card {
  width: 100%;
  .contact-header {
    text-align: center;
    align-items: center;
    margin-bottom: 30px;
    width: 100%;

    .contact-logo {
      display: flex;
      justify-content: center;
      margin-bottom: 20px;
      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .contact-info {
      h2 {
        font-size: 18px;
        color: #333;
        margin: 0;
        margin-bottom: 10px;
      }

      .email {
        margin-bottom: 20px;
      }
      .email,
      .phone {
        margin: 5px 0;
        font-size: 14px;
        color: #666;
      }
    }
  }

  .contact-address {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666;
  }
}

.call-now {
  background: #ff9900;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;

  &:hover {
    background: #e68a00;
  }
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 25px;

  a {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }

    &.whatsapp {
      //background: #25d366;
    }

    &.viber {
      //background: #665cac;
    }
  }
}
</style>
