
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ru",
  "ka"
]

export const localeLoaders = {
  "en": [{ key: "../../../../src/lang/en.json", load: () => import("../../../../src/lang/en.json" /* webpackChunkName: "locale__app_apps_crm_src_lang_en_json" */), cache: true },
{ key: "../../../../src/lang/api.ts", load: () => import("../../../../src/lang/api.ts?hash=c5fec032&locale=en" /* webpackChunkName: "locale__app_apps_crm_src_lang_api_ts" */), cache: false }],
  "ru": [{ key: "../../../../src/lang/ru.json", load: () => import("../../../../src/lang/ru.json" /* webpackChunkName: "locale__app_apps_crm_src_lang_ru_json" */), cache: true },
{ key: "../../../../src/lang/api.ts", load: () => import("../../../../src/lang/api.ts?hash=c5fec032&locale=en" /* webpackChunkName: "locale__app_apps_crm_src_lang_api_ts" */), cache: false }],
  "ka": [{ key: "../../../../src/lang/ka.json", load: () => import("../../../../src/lang/ka.json" /* webpackChunkName: "locale__app_apps_crm_src_lang_ka_json" */), cache: true },
{ key: "../../../../src/lang/api.ts", load: () => import("../../../../src/lang/api.ts?hash=c5fec032&locale=en" /* webpackChunkName: "locale__app_apps_crm_src_lang_api_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../../../../i18n.config.ts?hash=99279338&config=1" /* webpackChunkName: "_app_apps_crm_i18n_config_ts_99279338" */),
  () => import("../../../../i18n.config.ts?hash=99279338&config=1" /* webpackChunkName: "_app_apps_crm_i18n_config_ts_99279338" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "jsTsFormatResource": true
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "/app/apps/crm/i18n.config.ts",
  "locales": [
    {
      "code": "en",
      "label": "EN",
      "files": [
        "/app/apps/crm/src/lang/en.json",
        "/app/apps/crm/src/lang/api.ts"
      ],
      "language": "en-US"
    },
    {
      "code": "ru",
      "label": "RU",
      "files": [
        "/app/apps/crm/src/lang/ru.json",
        "/app/apps/crm/src/lang/api.ts"
      ],
      "language": "ru-RU"
    },
    {
      "code": "ka",
      "label": "KA",
      "files": [
        "/app/apps/crm/src/lang/ka.json",
        "/app/apps/crm/src/lang/api.ts"
      ],
      "language": "ka-GE"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "ka",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "label": "EN",
    "files": [
      {
        "path": "/app/apps/crm/src/lang/en.json"
      },
      {
        "path": "/app/apps/crm/src/lang/api.ts"
      }
    ],
    "language": "en-US"
  },
  {
    "code": "ru",
    "label": "RU",
    "files": [
      {
        "path": "/app/apps/crm/src/lang/ru.json"
      },
      {
        "path": "/app/apps/crm/src/lang/api.ts"
      }
    ],
    "language": "ru-RU"
  },
  {
    "code": "ka",
    "label": "KA",
    "files": [
      {
        "path": "/app/apps/crm/src/lang/ka.json"
      },
      {
        "path": "/app/apps/crm/src/lang/api.ts"
      }
    ],
    "language": "ka-GE"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
