import {
  createSection
} from "@formkit/inputs";

type Attrs = Record<string, string | number | boolean | undefined>

type Options = {
  attrs?: Attrs
}
// @ts-ignore
export const outerSection = ({attrs}: Options = {}) => {
  return createSection(
    'outer',
    () => ({
      $el: 'div',
      attrs: {
        key: '$id',
        'data-family': '$family || undefined',
        'data-type': '$type',
        'data-multiple':
          '$attrs.multiple || ($type != "select" && $options != undefined) || undefined',
        'data-disabled': '$disabled || undefined',
        'data-complete': '$state.complete || undefined',
        'data-invalid':
          '$state.valid === false && $state.validationVisible || undefined',
        'data-errors': '$state.errors || undefined',
        'data-submitted': '$state.submitted || undefined',
        'data-prefix-icon': '$_rawPrefixIcon !== undefined || undefined',
        'data-suffix-icon': '$_rawSuffixIcon !== undefined || undefined',
        'data-prefix-icon-click': '$onPrefixIconClick !== undefined || undefined',
        'data-suffix-icon-click': '$onSuffixIconClick !== undefined || undefined',

        // custom
        ...attrs,
      },
    }),
    true
  );
}

// export const fragment = createSection('wrapper', null, true)

export const outerFragment = ({attrs}: Options = {}) => {
  return createSection(
    'wrapper',
    () => ({
      $el: 'div',
      attrs: {
        key: '$id',
        'data-family': '$family || undefined',
        'data-type': '$type',
        'data-multiple':
          '$attrs.multiple || ($type != "select" && $options != undefined) || undefined',
        'data-disabled': '$disabled || undefined',
        'data-complete': '$state.complete || undefined',
        'data-invalid':
          '$state.valid === false && $state.validationVisible || undefined',
        'data-errors': '$state.errors || undefined',
        'data-submitted': '$state.submitted || undefined',
        // custom
        ...attrs,
      },
    }),
    true
  );
}
