<script lang="ts" setup>
const props = defineProps({
  context: Object,
})

const isHidden = ref(true);

function handleInput(e) {
  props.context.node.input(e.target.value)
}
</script>

<template>
  <div class="password-input">
    <input
      v-bind="$attrs"
      :type="isHidden ? 'password' : 'text'"
      :placeholder="props.context.attrs.placeholder"
      :value="props.context._value"
      @input="handleInput"
    >
    <button
      class="btn"
      type="button"
      @click="isHidden = !isHidden"
    >
      <svg
        width="14"
        height="10"
        viewBox="0 0 14 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0.848858 0.099717C0.621362 -0.0604358 0.291055 -0.0233272 0.111683 0.179793C-0.0676884 0.382914 -0.0261266 0.677829 0.201369 0.837982L13.1511 9.90028C13.3786 10.0604 13.7089 10.0233 13.8883 9.82021C14.0677 9.61709 14.0261 9.32217 13.7986 9.16202L11.4974 7.55268C12.3637 6.75973 12.9499 5.87107 13.2452 5.24023C13.3174 5.08594 13.3174 4.91406 13.2452 4.75977C12.9193 4.06252 12.2346 3.04692 11.2109 2.19928C10.1828 1.34383 8.76747 0.625096 7 0.625096C5.50815 0.625096 4.26567 1.13876 3.29663 1.81257L0.848858 0.099717ZM4.88035 2.91997C5.43815 2.4649 6.18408 2.18756 7 2.18756C8.73903 2.18756 10.1499 3.4473 10.1499 5C10.1499 5.48632 10.0121 5.94334 9.76933 6.34177L8.92497 5.75194C9.03871 5.52147 9.09996 5.26757 9.09996 5C9.09996 3.96487 8.15935 3.12504 7 3.12504C6.93875 3.12504 6.8775 3.12699 6.81625 3.13285C6.93219 3.31449 7 3.52542 7 3.75003C7 3.94924 6.9475 4.13674 6.85563 4.30275L4.88035 2.91997ZM9.76058 8.74015L8.15935 7.61518C7.80061 7.74213 7.40906 7.81244 7 7.81244C5.26097 7.81244 3.85006 6.5527 3.85006 5C3.85006 4.86524 3.86099 4.73438 3.88068 4.60548L1.8179 3.15434C1.31916 3.7344 0.962606 4.31252 0.754797 4.75977C0.682611 4.91406 0.682611 5.08594 0.754797 5.24023C1.08073 5.93748 1.7654 6.95308 2.78914 7.80072C3.81724 8.65617 5.23253 9.3749 7 9.3749C8.04561 9.3749 8.96653 9.12296 9.76058 8.74015Z"
          fill="#1F163B"
          fill-opacity="0.5"
        />
      </svg>
    </button>
  </div>
</template>

<style lang="scss">
[data-type="password"] {
  @import "../assets/scss/errors";

  &[data-disabled] {
    opacity: 30%;
  }

  width: 100%;
  margin-bottom: 20px;

  .indigo-ui-inner {
    width: 100%;
    box-shadow: none;
    border: 1px solid rgba(45, 45, 134, 0.2);
    border-radius: 10px;
    position: relative;
    overflow: hidden;

    input {
      appearance: none;
      width: 100%;
      height: 36px;
      background: transparent;
      padding: 0 15px;
      border: none;
      color: var(--text-color-1);

      &::placeholder {
        color: rgba(45, 45, 134, 0.2);
      }
    }

    .btn {
      position: absolute;
      right: 16px;
      top: 50%;
      transform: translateY(-50%);

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .indigo-ui-input {
      width: 100%;
    }
  }
}

</style>
