import {undefine} from "@formkit/utils";
import {clone} from '@formkit/utils'

export function requiredAsteriskPlugin(node) {
  const pluginEnabled = undefine(node.props.validation)
  if (pluginEnabled) {

    node.on('created', () => {
      if (!node.props || !node.props.definition || !node.context) return

      const options = node.props.validation.split('|');

      if (!options.includes('required')) return

      const inputDefinition = clone(node.props.definition)

      const originalSchema = inputDefinition.schema
      if (typeof originalSchema !== 'function') return

      const higherOrderSchema = (extensions: FormKitSectionsSchema) => {

        extensions.outer = {
          attrs: {
            'data-asterisk': 'true',
          },
        }

        const inputSchema = originalSchema(extensions);
        return inputSchema
      }

      inputDefinition.schema = higherOrderSchema

      if (inputDefinition.schemaMemoKey) {
        inputDefinition.schemaMemoKey += '-with-asterisk'
      }
      node.props.definition = inputDefinition
    })
  }
}
