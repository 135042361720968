<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CarRequestedServiceProps>(), {
  code: "defaultcode"
})
import { toRefs as __MACROS_toRefs } from "vue";
import {reset} from "@formkit/core";

import {useClientServiceForm, useServiceForm} from "~/composables/api";

interface CarRequestedServiceProps {
  form?: object
  code?: string
}

const library = markRaw({})

const {
  form,
  code,
} = __MACROS_toRefs(__MACROS_props);

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const {t} = useI18n({
  useScope: 'global'
})

const onModalSubmit = async (formData, node) => {
  console.log('formData', formData)
  console.log('node', node)
  try {
    const client = useClient()
    const result = await client.requestedClientService.create.mutate(formData)
    // emit('request', 'damage', result)
    emit('refresh')
    emit('close')
    // onCloseModal()
  } catch (e) {
    node.setErrors(
      [e.message],
    )
    return false;
  }
}
const onDamageModalReset = () => {
  reset('damage-form')
}

const onCloseModal = () => {
  reset('damage-form')
}


const initOptions = (options: object) => {
  try {
    return options.map((option: any) => {
      return {
        label: t(`modal.requestClientService.${option.label}`),
        value: option.value
      }
    })
  } catch (e) {
    return []
  }
}

const initSchema = (schema: any) => {
  if (!schema) return []
  if (!Array.isArray(schema)) return initSchema([schema])
  return schema.map((item: any) => {
    if (item.children) {
      item.children = initSchema(item.children)
    }
    if (item.initOptions) {
      item.options = initOptions(item.initOptions)
      delete item.initOptions
    }
    return item
  })
}

const onFormChange = useDebounceFn(async (formData) => {
  const {data: form, pending} = await useClientServiceForm({
    code: code.value,
    formData: formData
  });

  formSchema.value = initSchema(form.value)
}, 1000, {maxWait: 5000})

const formkitData = reactive({
  t,
  onCloseModal,
  onModalSubmit,
  onDamageModalReset,
  onFormChange,
})

const formSchema = ref([])

watch(form, () => {
  formSchema.value = initSchema(form.value)
}, {immediate: true})

</script>

<template>
  <div class="requested-service-modal">
    <FormKitSchema
      :schema="formSchema"
      :data="formkitData"
      :library="library"
    />
  </div>
</template>

<style lang="scss" scoped>
.requested-service-modal {
  :deep(.two-columns) {
    display: flex;
    max-width: 100%;
    overflow: hidden;

    .indigo-ui-outer {
      margin-right: 20px;

      &:last-child {
        margin: 0;
      }
    }
  }

  :deep(.arrow) {
    width: 16px;
    height: 14px;
    margin: 25px auto 5px;
    transform: rotate(90deg);
  }
}


</style>
