

export const reflectResponse = (data: any) => {
  if (!data) {
    return;
  }

  const { action } = data;
  if (action === 'download') {
    const { url, fileName } = data;

    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("target", "_blank");
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  if (action === 'saveAsFile') {
    const { fileData, fileName, mime = 'text/plain', base64 = false } = data;

    const element = document.createElement("a");
    if (base64) {
      element.setAttribute("href", `data:${mime};base64,${encodeURIComponent(fileData)}`);
    } else {
      element.setAttribute("href", `data:${mime};charset=utf-8,${fileData}`);
    }
    element.setAttribute("target", "_blank");
    element.setAttribute("download", fileName);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}
