<script setup lang="ts">
const props = defineProps({
  value: {
    type: Number,
    default: 0,
  },
  currencyCode: {
    type: String,
    default: 'USD',
  },
})

const formatValue = (value: number, currencyCode = 'USD') => {
  //TODO: fix hydration mismatch
  return value.toLocaleString('ru-RU', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  })
}
</script>

<template>
  {{ formatValue(props.value, props.currencyCode) }}
</template>
