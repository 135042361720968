<script lang="ts" setup>
const __MACROS_props = withDefaults(defineProps<RenderDocxProps>(), {
  title: 'Title',
  width: '420px',
  maxHeight: '50vh',
  open: false,
  closable: true,
})
import { toRefs as __MACROS_toRefs } from "vue";


const emit = defineEmits(['close'])

interface RenderDocxProps {
  title?: string
  width?: string
  maxHeight?: string
  open?: boolean
  closable?: boolean
}
const {
  title,
  width,
  maxHeight,
  open,
  closable,
} = __MACROS_toRefs(__MACROS_props);

const scrollContainer = ref(null);
const scroll = useScroll(scrollContainer)

const target = ref(null)

onClickOutside(target, (event) => {
  if (!closable.value){
    return;
  }
  if (event.target.classList.contains('modal-wrapper')) {
    emit('close')
    return;
  }
})

</script>

<template>
  <span style="display: none">
    <Teleport to="#teleports">
      <div
        v-if="open"
        class="modal-mask"
      >
        <div
          class="modal-wrapper"
        >
          <div
            ref="target"
            class="modal-container"
            :style="[{width}]"
          >
            <div class="modal-header">
              <slot name="header">
                <span class="title">{{ title }}</span>
                <button
                  v-if="closable"
                  class="btn-close"
                  @click="$emit('close')"
                >
                  <img src="~/assets/img/icons/close.svg">
                </button>
              </slot>
            </div>

            <div class="modal-body">
              <AppScrollContainer
                ref="scrollContainer"
                :style="[{maxHeight}]"
              >
                <slot :scroll="scroll" />
              </AppScrollContainer>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </span>
</template>


<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 420px;
  margin: 0px auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header {
  position: relative;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  padding: 20px 50px 10px;

  color: #1F163B;
  //border-bottom: 1px solid linear-gradient(58.17deg, #2D2D86 0.79%, #F0494F 100%);;

  &::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(58.17deg, #2D2D86 0.79%, #F0494F 100%);
  }

  .btn-close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.modal-body {
  padding: 20px 50px;
}

.modal-default-button {
  float: right;
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  transform: scale(1.1);
}

:deep(*) {
  .ps {
    max-height: 520px !important;
    &.ps--active-y {
      padding-right: 15px;
      margin-right: -15px;
    }
  }

  .arrow {
    width: 16px;
    height: 14px;
    margin: 25px auto 5px;
    transform: rotate(90deg);
  }

  .indigo-ui-outer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .item-label {
    display: block;
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 8px;
  }

  .two-columns {
    display: flex;
    max-width: 100%;
    overflow: hidden;

    .indigo-ui-outer {
      margin-right: 20px;
      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
