<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<MdViewProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";

import {VMdPreview} from "~/libs/markdown/editor";

interface MdViewProps {
  md: string;
}

const { md } = __MACROS_toRefs(__MACROS_props);

</script>

<template>
  <div class="markdown-body">
    <VMdPreview
      :text="md"
    />
  </div>
</template>

<style lang="scss">
.v-md-editor-preview,
.markdown-body {
  @import "../../libs/markdown/assets/styles.scss";
}
</style>

<style scoped lang="scss">
.markdown-body {
  :deep(.github-markdown-body) {
    padding: 0;
    font-family: Eurostile,sans-serif;
    & > p:not([class]){
      text-indent: 1.5em;
    }
  }
}
</style>
