<script lang="ts" setup></script>

<template>
  <div class="scroller">
    <slot />
  </div>
</template>

<style scoped lang="scss">
.scroller {
  overflow: auto;
  overflow-x: hidden;
}

</style>
