<script lang="ts">
const optionPath = 'value'
const MAX_ITEMS = 50
</script>
<script lang="ts" setup>
import { Check, ChevronsUpDown } from 'lucide-vue-next'
import { cn } from '@/lib/utils'
import { Button } from '@/components/shadcn/button'
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList
} from '@/components/shadcn/command'
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/shadcn/popover'


const props = defineProps({
  context: {
    type: Object,
  },
})

const open = ref(false)

const search = ref('')

const selected = ref(props.context!._value || '') // || options[0]?.[optionPath]

const selectedOption = computed(() => {
  if (!props.context!.options) {
    return null
  }
  return props.context!.options.find(option => option[optionPath] === props.context!._value)
})


const onSearch = (event) => {
  search.value = event.target.value
}



const filteredOptions = computed(() => {
  if (!props.context!.options) {
    return []
  }
  let items = props.context!.options
  console.log('search search',search.value)

  if (search.value === '') {
    items = items.slice(0, MAX_ITEMS)
    console.log('search',items)
    return items
  }

  items = props.context!.options.filter(option => {
    const searchTerm = search.value.trim().toLowerCase()
    console.log(searchTerm)
    return option.label.toLowerCase().includes(searchTerm)
  })

  items = items.slice(0, MAX_ITEMS)
  console.log('items',items)
  return items
})


const test = ref('')
const onSelect = ({detail}) => {
  console.log('onSelect', detail)
  selected.value = detail?.value?.value
  open.value = false
  props.context.node.input(detail?.value?.value)
}

</script>

<template>
  <div>
    <Popover
      v-model:open="open"
    >
      <PopoverTrigger
        class="w-full"
        as-child
      >
        <Button
          variant="outline"
          role="combobox"
          :aria-expanded="open"
          class="w-full justify-between"
        >
          {{ selectedOption?.label || props.context.placeholder || 'Select' }}
          <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        class="PopoverContent w-full p-0"
      >
        <Command>
          <CommandInput
            v-if="props.context.searchable !== undefined"
            :placeholder="`Search ${props.context.placeholder ?? '' }`"
            @input="onSearch"
          />
          <CommandList>
            <CommandEmpty>No options found.</CommandEmpty>
            <CommandGroup>
              <CommandItem
                v-for="option in filteredOptions"
                :key="option.value"
                :value="option"
                @select="onSelect"
              >
                <Check
                  :class="cn(
                    'mr-2 h-4 w-4',
                    selectedOption?.label === option?.label ? 'opacity-100' : 'opacity-0',
                  )"
                />
                {{ option.label }}
              </CommandItem>
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  </div>
</template>

<style lang="scss">

.PopoverContent {
  width: var(--radix-popper-anchor-width);
  max-height: var(--radix-popover-content-available-height);
  z-index: 1001;
}

[data-type="dropdown"] {
  margin-bottom: 20px;
  width: 100%;

  &[data-disabled] {
    opacity: 30%;
  }

  &[data-invalid="true"] {
    .dropdown-container {
      color: rgba(31, 22, 59, 0.5);
      background: rgba(240, 73, 79, 0.1);
    }

    .indigo-ui-messages {
      margin-top: 5px;

      .indigo-ui-message {
        font-family: "Eurostile", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #F0494F;
      }
    }
  }

  .dropdown {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;

    font-family: "Eurostile", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    .search-input{
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-family: "Eurostile", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #1F163B;
      z-index: 1;
    }

    .test {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }


    .dropdown-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1F163B;
      cursor: pointer;
      user-select: none;

      background: #FFFFFF;
      border: 1px solid rgba(45, 45, 134, 0.2);
      border-radius: 10px;

      padding: 11px;

      //&:after {
      //  position: absolute;
      //  content: "";
      //  top: 22px;
      //  right: 1em;
      //  width: 0;
      //  height: 0;
      //  border: 5px solid transparent;
      //  border-color: #fff transparent transparent transparent;
      //}

      &.open {
        //border: 1px solid #ad8225;
        //border-radius: 6px 6px 0px 0px;
      }
    }

    .dropdown-item {
      &.selected {
        background: rgba(45, 45, 134, 0.1);

        &:hover {
          background: rgba(45, 45, 134, 0.05);
        }
      }
    }


  }
}

</style>
<style lang="scss">
.items {

  font-family: "Eurostile", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  display: none;
  color: #1F163B;
  overflow-y: auto;

  max-height: 300px;

  position: fixed;
  background-color: white;
  padding: 10px;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 1500;

  border: 1px solid rgba(45, 45, 134, 0.2);
  box-shadow: 0px 4px 20px rgba(31, 22, 59, 0.1);
  border-radius: 10px;

  &.hide {
    display: none;
  }

  &.open {
    display: block;
  }

  div {
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    padding: 11px;

    &:hover {
      background: rgba(45, 45, 134, 0.1);
    }
  }
}
</style>
