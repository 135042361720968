<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<ReadInboxProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";


interface ReadInboxProps {
  message: object
}

const {message} = __MACROS_toRefs(__MACROS_props);


const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const {t} = useI18n({
  useScope: 'global'
})

</script>

<template>
  <div class="read-inbox-blocking-message">
    <h3 class="read-inbox-blocking-message__subject">
      {{ message.subject }}
    </h3>
    <div class="read-inbox-blocking-message__message">
      <AppMdView :md="message.message" />
    </div>
    <FormKit
      type="button"
      :label="t('common.accept')"
      @click="emit('confirm')"
    />
  </div>
</template>

<style lang="scss" scoped>

.read-inbox-blocking-message {
  &__subject {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__message {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 24px;
  }
}
</style>
