import {useModalsStore} from "../stores/modalsStore";
import {useAuthStore} from "../stores/authUser";

export default defineNuxtPlugin(() => {
  addRouteMiddleware((to, from) => {
    if (process.server) {
      return;
    }

    if (to.path.indexOf('/dealer') === -1 ) {
      return;
    }

    const authStore = useAuthStore();
    const modalStore = useModalsStore()

    if (!authStore.user) {
      return;
    }

    if (authStore.user.roleId === 1 || authStore.user.roleId === 3) {
      return;
    }

    if (authStore.user.acceptTerms) {
      return;
    }

    //TODO: check better way to block ui
    setTimeout(() => {
      modalStore.openModal('UserTerms')
    }, 1500)
  })
})
