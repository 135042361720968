<script lang="ts" setup>
const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  value: {
    type: String,
    default: "",
  },
  type: {
    type: String,
    default: "text",
  },
  link: {
    type: String,
    default: null,
  },
});

const currentType = computed(() => {
  if (props.type === "link" && props.link && props.link.indexOf("http") !== -1) {
    return "link";
  }

  if (props.type === "text") {
    return "text";
  }

  if (props.type === "date") {
    return "date";
  }
  return "text";
});
</script>

<template>
  <div class="item">
    <span class="item-title"><slot name="title">{{ props.title }}</slot>:</span>
    <template v-if="currentType == 'link'">
      <span class="item-value">
        <slot>
          <a
            :href="props.link"
            rel="noopener noreferrer"
            target="_blank"
          >{{ props.value }}</a>
        </slot>
      </span>
    </template>
    <template v-else-if="currentType == 'text'">
      <span class="item-value"><slot>{{ props.value }}</slot></span>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-bottom: 11px;

  @media only screen and (max-width: $tablet) {
    margin-bottom: 10px;
  }

  &-title {
    align-self: start;
    margin-right: 5px;
  }

  &-value {
    align-self: start;
    font-weight: 600;
  }
}
</style>
