import { acceptHMRUpdate, defineStore } from "pinia";

type ModalType = "UserTerms" | "CarTerms";

interface State {
  activeModal: ModalType | null;
  prevModal: ModalType | null;
}

/**
 * @deprecated use vue-final-modal
 */
export const useModalsStore = defineStore("modals", {
  state: (): State => ({
    prevModal: null,
    activeModal: null,
  }),
  getters: {
    isModalOpen: (state) => (modal: ModalType) => {
      return state.activeModal === modal;
    },
  },
  actions: {
    async openModal(modal: ModalType | null) {
      this.prevModal = this.activeModal;
      this.activeModal = modal;
    },
    async closeModal(modalName: string) {
      //TODO: allow stack of modals
      this.activeModal = null;
      this.prevModal = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useModalsStore, import.meta.hot));
}
