

export const useSsrCookie = () => {
  const headers = useRequestHeaders(['cookie']);
  let documentCookie = ''
  if (process.client) {
    documentCookie = document?.cookie;
  }
  const cookie = headers.cookie || documentCookie || '';
  return cookie
}
