import {FormKitTypeDefinition} from "@formkit/core";
import {
  wrapper,
  help,
  messages,
  message,
  icon,
  prefix,
  suffix,
  buttonLabel,
  localize,
  ignores,
  $attrs, buttonInput,
} from "@formkit/inputs";
import {outerSection} from "~/libs/formkit/utils";

import './action.scss'

/**
 * Input definition for a action.
 * @public
 */

const actionOuter = outerSection({
  attrs: {
    'data-type': "action",
    'data-variant': "$variant || 'primary'",
    'data-size': "$size || 'medium'",
    'data-width': "$width || 'normal'"
  }
})
export const action: FormKitTypeDefinition = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: actionOuter(
    messages(message("$message.value")),
    wrapper(
      $attrs(
        {
          bind: '$actionAttrs',
          ignore: true,
          type: 'button',
          disabled: '$disabled',
          label: '$actionLabel',
        },
        buttonInput(
          icon("prefix"),
          prefix(),
          buttonLabel("$label || $ui.button.value"),
          suffix(),
          icon("suffix")
        )
      )
    ),
    help("$help")
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: "input",
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: "action",
  /**
   * An array of extra props to accept for this input.
   */
  props: ["variant", "size", "width"],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: "button",
  /**
   * Additional features that should be added to your input
   */
  features: [localize("action"), ignores],
};
