<script setup lang="ts"></script>

<template>
  <div>
    <ModalUserTerms />
    <ModalCarTerms />
  </div>
</template>

<style scoped></style>
