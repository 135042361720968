type ParsedCookies = {
  [key: string]: string;
};

export function parseCookie(cookieHeader: string): ParsedCookies {
  const parsedCookies: ParsedCookies = {};

  // Split the cookie header string into individual cookie strings
  const cookieStrings = cookieHeader.split("; ");

  for (const cookieString of cookieStrings) {
    // Split the cookie string into key-value pair
    const [key, value] = cookieString.split("=");

    // Decode the key and value, then add them to the parsedCookies object
    parsedCookies[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return parsedCookies;
}

export function parseFetchCookie(cookieHeader: string): ParsedCookies {
  const parsedCookies: ParsedCookies = {};

  // Split the cookie header string into individual cookie strings
  const cookieStrings = cookieHeader.split(", ");

  for (const cookieString of cookieStrings) {
    // Split the cookie string into key-value pair
    const [keyValue] = cookieString.split(";");
    const [key, value] = keyValue.split("=");

    // Decode the key and value, then add them to the parsedCookies object
    parsedCookies[decodeURIComponent(key)] = decodeURIComponent(value);
  }

  return parsedCookies;
}
