<script lang="ts">
const optionPath = 'value'
</script>
<script lang="ts" setup>
import {useElementBounding} from '@vueuse/core'

const props = defineProps({
  context: {
    type: Object,
  },
})

const open = ref(false)
const droplist = ref<HTMLElement>()
const searchInput = ref<HTMLInputElement>()
const dropdown = ref(null)

const search = ref('')

const selected = ref(props.context!._value || '') // || options[0]?.[optionPath]
const selectedOption = computed(() => {
  if (!props.context!.options) {
    return null
  }
  return props.context!.options.find(option => option[optionPath] === props.context!._value)
})

onClickOutside(droplist, (event) => {
  if (!droplist.value?.contains(event.target)) {
    onBlur();
  }
})

const onSearch = (event) => {
  search.value = event.target.value
}

const filteredOptions = computed(() => {
  if (!props.context!.options) {
    return []
  }

  if (!search.value) {
    return props.context!.options
  }

  return props.context!.options.filter(option => {
    return option.label.toLowerCase().includes(search.value.trim().toLowerCase())
  })
})

const {x, y, top, right, bottom, left, width, height} = useElementBounding(dropdown)

const dropdownStyle = computed(() => {
  return {
    top: `${bottom.value + 10}px`,
    left: `${left.value - 2}px`,
    width: `${width.value + 4}px`,
  }
})

function handleSelect(option, event) {
  event.stopPropagation()
  selected.value = option?.[optionPath];
  open.value = false;
  props.context.node.input(option?.[optionPath])
}

function onBlur() {
  open.value = false;
  // props.context.handlers.blur()
}

function onFocus() {
  open.value = true;
  if (props.context.searchable !== undefined) {
    requestAnimationFrame(() => {
      setTimeout(() => {
        if (searchInput.value) {
          searchInput.value.style.display = 'block';
          searchInput.value.style.opacity = '1';
          searchInput.value?.focus();
          searchInput.value?.select();

          // searchInput.value?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 250);
    });
  }
  // props.context.handlers.focus()
}
</script>

<template>
  <div
    ref="dropdown"
    class="dropdown"
    @blur="onBlur"
  >
    <input
      :id="context.id"
      type="hidden"
      :name="context.node.name"
    >
    <div
      class="dropdown-container"
      :class="[open]"
      @click="onFocus"
    >
      <div
        v-if="open"
        class="test"
      >
        <template v-if="props.context.searchable !== undefined">
          <input
            ref="searchInput"
            :key="context.id"
            class="search-input"
            :value="search"
            type="text"
            autocomplete="off"
            @input="onSearch"
          >
        </template>
        <template v-else>
          <slot name="default">
            <span>{{ selectedOption?.label || `${props.context.placeholder}` }}</span>
          </slot>
          <span class="icon"><img src="~/assets/img/icons/arrow-down.svg"></span>
        </template>
      </div>
      <div
        v-else
        class="test"
      >
        <slot name="default">
          <span>{{ selectedOption?.label || props.context.placeholder || 'Select' }}</span>
        </slot>
        <span class="icon"><img src="~/assets/img/icons/arrow-down.svg"></span>
      </div>
    </div>

    <Teleport to="#teleports">
      <div
        v-if="open"
        ref="droplist"
        class="items"
        :class="[{open: open}]"
        :style="dropdownStyle"
      >
        <template v-if="filteredOptions && filteredOptions.length > 0">
          <div
            v-for="(option, i) of filteredOptions"
            :key="i"
            class="dropdown-item"
            :class="[{selected: selectedOption?.label === option.label}]"
            @click.stop="handleSelect(option, $event)"
          >
            <slot
              name="default"
              v-bind="option"
            >
              {{ option.label }}
            </slot>
          </div>
        </template>
        <template v-else>
          <div class="no-results">
            <slot name="no-results">
              No Options found
            </slot>
          </div>
        </template>
      </div>
    </Teleport>
  </div>
</template>

<style lang="scss">
[data-type="dropdown"] {
  margin-bottom: 20px;
  width: 100%;

  &[data-disabled] {
    opacity: 30%;
  }

  &[data-invalid="true"] {
    .dropdown-container {
      color: rgba(31, 22, 59, 0.5);
      background: rgba(240, 73, 79, 0.1);
    }

    .indigo-ui-messages {
      margin-top: 5px;

      .indigo-ui-message {
        font-family: "Eurostile", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #F0494F;
      }
    }
  }

  .dropdown {
    position: relative;
    width: 100%;
    text-align: left;
    outline: none;

    font-family: "Eurostile", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    .search-input{
      width: 100%;
      border: none;
      outline: none;
      background: transparent;
      font-family: "Eurostile", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #1F163B;
    }

    .test {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }


    .dropdown-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #1F163B;
      cursor: pointer;
      user-select: none;

      background: #FFFFFF;
      border: 1px solid rgba(45, 45, 134, 0.2);
      border-radius: 10px;

      padding: 11px;

      //&:after {
      //  position: absolute;
      //  content: "";
      //  top: 22px;
      //  right: 1em;
      //  width: 0;
      //  height: 0;
      //  border: 5px solid transparent;
      //  border-color: #fff transparent transparent transparent;
      //}

      &.open {
        //border: 1px solid #ad8225;
        //border-radius: 6px 6px 0px 0px;
      }
    }

    .dropdown-item {
      &.selected {
        background: rgba(45, 45, 134, 0.1);

        &:hover {
          background: rgba(45, 45, 134, 0.05);
        }
      }
    }


  }
}

</style>
<style lang="scss">
.items {

  font-family: "Eurostile", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  display: none;
  color: #1F163B;
  overflow-y: auto;

  max-height: 300px;

  position: fixed;
  background-color: white;
  padding: 10px;
  top: calc(100% + 5px);
  left: 0;
  right: 0;
  z-index: 1500;

  border: 1px solid rgba(45, 45, 134, 0.2);
  box-shadow: 0px 4px 20px rgba(31, 22, 59, 0.1);
  border-radius: 10px;

  &.hide {
    display: none;
  }

  &.open {
    display: block;
  }

  div {
    border-radius: 8px;
    cursor: pointer;
    user-select: none;
    padding: 11px;

    &:hover {
      background: rgba(45, 45, 134, 0.1);
    }
  }
}
</style>
