import {defineStore} from "pinia";
import {tokenPayload} from "api/src/modules/auth/feature/accessToken";
import {z} from "zod";

interface State {
  user: z.infer<typeof tokenPayload> | null;
  userToken: string | null;
}

export const useAuthStore = defineStore("auth", {
  // convert to a function
  state: (): State => ({
    user: null,
    userToken: null,
  }),
  getters: {
    // fullName: (state) => `${state.firstName} ${state.lastName}`,
    canLoadUser: (state) => !!state.userToken,
    loggedIn: (state) => !!state.user?.id && !!state.userToken,
    getUserToken: (state) => {
      return state.userToken
    },
    isAdmin: (state) => state.user?.roleId === 1,
    isDealer: (state) => state.user?.roleId === 2,
    isBackOffice: (state) => state.user?.roleId === 3,
    viewName: (state) => {
      // console.log(state.user)
      return state.user?.username
    },
    getSubscriptionTypeId: (state) => {
      return state.user?.client?.subscription
    },
    getSubscriptionLabel(state) {
      const {t} = useI18n({
        useScope: 'global'
      })
      switch (state.user?.client?.subscription) {
        // case 1:
        //   return t('nav.user.subscription.standart')
        // case 2:
        //   return t('nav.user.subscription.premium')
        default:
          return state.user?.client?.subscription
      }
    },
    canAccess: (state) => (requestRoles: number[]) => {
      if (!state.user) return false;
      return requestRoles.includes(state.user?.roleId);
    }
  },
  actions: {
    async loadUser() {
      const {$client} = useNuxtApp()

      try {
        const res = await $client.user.me.query();
        const { preferences }  = res
        updateAppConfig({preferences})
        this.updateUser(res);
      } catch (e: Error) {
        console.log('e', e.message)
      }
    },

    updateUser(payload: any) {
      // console.log(payload);
      this.user = payload;
      // this.firstName = payload.firstName;
      // this.lastName = payload.lastName;
      // this.userId = payload.userId;
    },
    setUserToken(token: string | null) {
      //TODO: fix token in html response
      this.userToken = token;
    },
    async refreshUserToken() {
      // const {$client} = useNuxtApp();
      // const res = await $client.auth.accessToken.mutate();
      // console.log('resresres', res)
    },
    async login(payload: { username: string; password: string }) {
      const {$client} = useNuxtApp();
      const res = await $client.auth.login.mutate({
        ...payload,
      });
      // localStorage.setItem("token", res.token);
      // console.log();
      this.setUserToken(res.token);
      await this.loadUser()
    },
    async reload() {
      const {$client} = useNuxtApp();
      await $client.auth.accessToken.mutate({});
      await this.loadUser()
    },
    async logout(redirectTo: string = '') {
      const {$client} = useNuxtApp();
      try {
        const res = await $client.auth.logout.mutate();
      } catch (e) {
        console.log(e);
      }
      // console.log(' async logout() {', res)
      this.clearUser();
      if (redirectTo) {
        return navigateTo(`/auth/login?redirect=${encodeURIComponent(redirectTo)}`);
      } else {
        navigateTo('/auth/login');
      }
    },
    clearUser() {
      this.$reset();
    },
  },
});
