<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CreateInboxBatchProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";


interface CreateInboxBatchProps {
}

// eslint-disable-next-line no-empty-pattern
const {} = __MACROS_toRefs(__MACROS_props);

const [rolesOptions] = useInboxFieldAutocomplete({ field: "toRoles" });
const [usersOptions] = useInboxFieldAutocomplete({ field: "toUsers" });
const [subscriptionsOptions] = useInboxFieldAutocomplete({ field: "toSubscriptions" });

const emit = defineEmits<{
  (e: 'confirm'): void
  (e: 'close'): void
  (e: 'refresh'): void
}>()

const {t} = useI18n({
  useScope: 'global'
})

const onModalSubmit = async (formData, node) => {
  try {
    const client = useClient()
    const result = await client.inbox.batchCreate.mutate(formData)
    emit('refresh')
    emit('close')
  } catch (e) {
    node.setErrors(
      [e.message],
    )
    return false;
  }
}

const test = reactive({
  form: {
    sendCommand: {
      toSubscriptions: [],
      toUsers: [],
      toRoles: [],
      isBlocking: true,
    }
  }
})

const userLabelById = (id?: number) => {
  if (!usersOptions.value) {
    return ''
  }
  if (!id) {
    return ''
  }
  const user = usersOptions.value.find((item) => item.value === id)
  return user ? user.label : ''
}

const onUserSelected = (value) => {
  test.form.sendCommand.toUsers.push(value)
  test.form.sendCommand.roles = null
}
const onSubscriptionSelected = (value) => {
  test.form.sendCommand.toSubscriptions.push(value)
  test.form.sendCommand.subscriptions = null
}

const roleToLabel = {
  1: 'Admin',
  2: 'Dealer',
  3: 'Back Office',
}

const onRolesSelected = (value) => {
  test.form.sendCommand.toRoles.push(value)
  test.form.sendCommand.roles = null
}
const onRemoveSubscription = (value) => {
  test.form.sendCommand.toSubscriptions = test.form.sendCommand.toSubscriptions.filter((item) => item !== value)
}
const onRemoveUser = (value) => {
  test.form.sendCommand.toUsers = test.form.sendCommand.toUsers.filter((item) => item!== value)
}
const onRemoveRole = (value) => {
  test.form.sendCommand.toRoles = test.form.sendCommand.toRoles.filter((item) => item!== value)
}

const subscriptionsLeft = computed(() => {
  if (!subscriptionsOptions.value) {
    return []
  }
  return subscriptionsOptions.value.filter((item) => {
    return !Object.values(test.form.sendCommand.toSubscriptions).includes(item.value)
  })
})
const usersLeft = computed(() => {
  if (!usersOptions.value) {
    return []
  }
  return usersOptions.value.filter((item) =>!Object.values(test.form.sendCommand.toUsers).includes(item.value))
})
const rolesLeft = computed(() => {
  if (!rolesOptions.value) {
    return []
  }
  return rolesOptions.value.filter((item) =>!Object.values(test.form.sendCommand.toRoles).includes(item.value))
})

</script>

<template>
  <div class="create-inbox-batch">
    <FormKit
      v-model="test.form"
      type="form"
      :actions="false"
      @submit="onModalSubmit"
    >
      <FormKit
        v-slot="{value}"
        type="group"
        name="sendCommand"
      >
        <FormKit
          type="dropdown"
          searchable="true"
          name="subscriptions"
          :label="t('modal.inbox.toSubscriptions')"
          :options="subscriptionsLeft"
          @input="onSubscriptionSelected"
        />
        <div class="chips-container">
          <span
            v-for="item of value.toSubscriptions"
            :key="item"
            class="chips"
            @click="onRemoveSubscription(item)"
          >{{ item }}</span>
        </div>

        <FormKit
          type="dropdown"
          name="users"
          searchable="true"
          :label="t('modal.inbox.toUsers')"
          :options="usersLeft"
          @input="onUserSelected"
        />
        <div class="chips-container">
          <span
            v-for="item of value.toUsers"
            :key="item"
            class="chips"
            @click="onRemoveUser(item)"
          >{{ userLabelById(item) }}</span>
        </div>

        <FormKit
          type="dropdown"
          searchable="true"
          name="roles"
          :label="t('modal.inbox.toRoles')"
          :options="rolesLeft"
          @input="onRolesSelected"
        />
        <div class="chips-container">
          <span
            v-for="item of value.toRoles"
            :key="item"
            class="chips"
            @click="onRemoveRole(item)"
          >{{ roleToLabel[item] ?? '' }}</span>
        </div>
      </FormKit>

      <FormKit
        type="text"
        name="subject"
        validation="required"
        :label="t('modal.inbox.subject')"
      />

      <FormKit
        type="markdown"
        name="message"
        validation="required"
        :label="t('modal.inbox.message')"
      />
      <FormKit
        type="group"
        name="sendCommand"
      >
        <FormKit
          type="checkbox"
          name="isBlocking"
          :label="t('modal.inbox.isBlocking')"
        />
      </FormKit>
      <div class="two-columns">
        <FormKit
          type="submit"
          variant="primary"
          :label="t('modal.inbox.submit')"
        />
      </div>
    </FormKit>
  </div>
</template>

<style lang="scss" scoped>
.chips-container{
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.chips{
  display: inline-block;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 5px;
  background-color: #f5f5f5;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  &:hover{
    background-color: #e5e5e5;
  }
}

</style>
