<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<PdfPreviewProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";
import { VuePDF, usePDF } from "@tato30/vue-pdf";


interface PdfPreviewProps {
  link: string;
}

const { link } = __MACROS_toRefs(__MACROS_props);


const { pdf, pages } = usePDF({
  url: link.value
});
</script>

<template>
  <div
    v-for="page in pages"
    :key="page"
  >
    <VuePDF
      :pdf="pdf"
      :page="page"
      fit-parent
    />
  </div>
</template>

<style scoped lang="scss">

</style>
