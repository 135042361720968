import {acceptHMRUpdate, defineStore} from "pinia";

export const useSelectedCarsStore = defineStore("selected-cars", () => {
  const cars = ref<number[]>([]);
  const popupOpen = ref(false);
  const carsData = ref([]);

  const saveCarsToLocalStorage = async () => {
    const client = useClient();
    await client.car.saveSelectedCars.mutate({selectedCars: cars.value});
  }
  const loadCarsFromLocalStorage = async () => {
    const client = useClient();
    try {
      cars.value = await client.car.loadSelectedCars.query();
    } catch (e) {
      console.error(e);
      cars.value = [];
    }
  }

  loadCarsFromLocalStorage();

  const updateList = async (value) => {
    if (!value) return;
    if (value.length === 0) {
      carsData.value = [];
      return;
    }

    const client = useClient();
    const result = await client.car.list.query({filters: {ids: value}});
    carsData.value = result.items;
  }

  watch(() => cars.value, (value) => {
    updateList(value);
  }, {immediate: true})

  const isCarSelected = (carId) => {
    if (!cars.value) return false;
    if (cars.value.length === 0) return false;
    if (!carId) return false;
    if (!Array.isArray(cars.value)) return false;
    return cars.value.includes(carId);
  }

  const addCar = async (carId: number) => {
    if (isCarSelected(carId)) return;

    const client = useClient();
    client.car.addSelectedCars.mutate({carId});

    cars.value.push(carId);
  }
  const removeCar = async (carId) => {
    if (!isCarSelected(carId)) return;

    const client = useClient();
    client.car.removeSelectedCars.mutate({carId});

    cars.value.splice(cars.value.indexOf(carId), 1);
  }

  const toggleCarSelection = async (carId) => {
    if (isCarSelected(carId)) {
      await removeCar(carId);
    } else {
      await addCar(carId);
    }
  }

  const runAction = async (action) => {
    switch (action) {
      case "invoice": {
        const {open} = await useRequestServiceModal({code: "BATCH_INVOICE", ids: cars.value});
        await open();
        break;
      }
    }
  }
  const clearCars = () => {
    popupOpen.value = false;
    const client = useClient();
    client.car.clearSelectedCars.mutate({});

    cars.value = [];
  }

  const loadingItemProxy = new Proxy({}, {
    get: function (target, prop, receiver) {
      return "Loading car..."
    }
  })

  const list = computed(() => {
    return cars.value.map((carId) => {
      return carsData.value.find((car) => car.id === carId) || loadingItemProxy;
    })
  })

  return {
    cars,
    carsData,
    list,
    popupOpen,
    addCar,
    removeCar,
    runAction,
    isCarSelected,
    toggleCarSelection,
    clearCars,
  };
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSelectedCarsStore, import.meta.hot));
}
