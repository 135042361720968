<script setup lang="ts">
const __MACROS_props = withDefaults(defineProps<CreateInboxBatchProps>(), {})
import { toRefs as __MACROS_toRefs } from "vue";

import {useSelectedCarsStore} from "~/stores/selected-cars";

const carsStore = useSelectedCarsStore();

interface CreateInboxBatchProps {
}

// eslint-disable-next-line no-empty-pattern
const {} = __MACROS_toRefs(__MACROS_props);

const {t} = useI18n({
  useScope: 'global'
})

const onRemoveCar = (id) => {
  carsStore.removeCar(id);
};

watch(carsStore.list, (value) => {
  if (value.length === 0) {
    emit('close')
  }
});
</script>

<template>
  <div class="selected-cars">
    <div
      v-for="car in carsStore.list"
      :key="car"
      class="car-item"
    >
      <div class="car-item__header">
        <div class="title">
          {{ car.title }}
        </div>

        <!--      <FormKit-->
        <!--        type="hidden"-->
        <!--        :value="car.id"-->
        <!--      />-->
        <div
          class="remove-car"
          @click="onRemoveCar(car.id)"
        >
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 0.0625C3.61562 0.0625 0.0625 3.61562 0.0625 8C0.0625 12.3844 3.61562 15.9375 8 15.9375C12.3844 15.9375 15.9375 12.3844 15.9375 8C15.9375 3.61562 12.3844 0.0625 8 0.0625ZM11.7375 10.6125C12.0469 10.9219 12.0469 11.425 11.7375 11.7344C11.4219 12.05 11.0438 12.1625 10.6156 11.7344L8 9.12187L5.3875 11.7344C5.025 12.0969 4.5 11.9688 4.26562 11.7344C3.95625 11.425 3.95625 10.9219 4.26562 10.6125L6.87813 8L4.26562 5.3875C3.95625 5.07812 3.95625 4.575 4.26562 4.26562C4.575 3.95625 5.07812 3.95625 5.3875 4.26562L8 6.87813L10.6125 4.26562C10.9219 3.95625 11.425 3.95625 11.7344 4.26562C12.0437 4.575 12.0437 5.07812 11.7344 5.3875L9.12187 8L11.7375 10.6125Z"
              fill="#1F163B"
            />
          </svg>
        </div>
      </div>
      <div>
        <div class="car-prop">
          {{ car.vinCode }}
        </div>
        <div class="car-prop">
          {{ car.receiver }}
        </div>
        <PageCarModuleFinanceInfoView
          :short="true"
          :view="car.financialInfo"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.car-item {
  padding: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  font-size: 12px;
  line-height: 14px;

  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  &:last-child {
    border-bottom: none;
  }

  &:hover {
    background: #eee;
  }

  a {
    color: var(--primary-color);
    text-decoration: none;

    &:hover {
      color: var(--primary-hover-color);
    }
  }
}

.car-item__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }

  .remove-car {
    cursor: pointer;

    svg {
      fill: #ccc;
    }

    &:hover {
      svg {
        fill: #000;
      }
    }
  }
}

.car-prop {
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 5px;
}
.selected-cars {
  margin-bottom: 20px;
}
</style>
