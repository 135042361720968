<script setup lang="ts">
import { PopoverTrigger as PopoverTriggerInner, type PopoverTriggerProps } from 'radix-vue'

const props = defineProps<PopoverTriggerProps>()
</script>

<template>
  <PopoverTriggerInner
    v-bind="props"
  >
    <slot />
  </PopoverTriggerInner>
</template>
