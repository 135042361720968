<script lang="ts" setup></script>
<template>
  <div class="card">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.card {
  position: relative;
  height: fit-content;
  padding: 20px;
  margin: 15px;
  z-index: 0;

  &::before,
  &::after {
    position: absolute;
    z-index: -2;
    content: "";
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border-radius: 10px;
    background: linear-gradient(45deg, rgba(45, 45, 134, 1) 0%, rgba(240, 73, 79, 1) 100%);
  }

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: -1;
  }
}

</style>

