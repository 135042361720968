import {useClient} from "~/composables/client";

export async function useBellNotifications() {
  const client = useClient();
  const notifications: any = ref([])

  const refresh = async () => {
    const {data} = await client.notification.list.useQuery({}, {})
    notifications.value = data.value
  }
  await refresh()
  // const {data, error, pending} = result
  return {data: notifications, refresh}
}

export async function useServices() {
  const client = useClient();
  const {data: services} = await client.service.list.useQuery();
  const featuredServices = services.value.filter((service) => service.isFeatured);
  const otherServices = services.value.filter((service) => !service.isFeatured);

  return {services: services.value, featuredServices, otherServices}
}

export async function useServiceActions(input) {
  const client = useClient();
  const services = await client.service.requestServiceActions.useQuery(input)

  return services
}

export async function useClientServicesActions(input) {
  const client = useClient();
  const services = await client.clientService.requestServiceActions.useQuery(input)

  return services
}

export async function useServiceForm(input) {
  const client = useClient();
  const services = await client.service.requestServiceForms.useQuery(input)

  return services
}
export async function useClientServiceForm(input) {
  const client = useClient();
  const services = await client.clientService.requestServiceForms.useQuery(input)

  return services
}

export const useCars = async () => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.car.list.query({
      filters: unref(filters),
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()


  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  return {
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useUsers = async  () => {
  const client = useClient()

  const filters = ref({})
  const data = ref([])
  const isLoading = ref(false)
  const error = ref(null)
  const currentPage = ref(1)
  const currentPageSize = ref(10)
  const total = ref(10)

  // const pagination = reactive({
  //   currentPage: 1,
  //   currentPageSize: 10,
  //   total: 0,
  // })

  const {
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
  });

  async function fetchData() {
    isLoading.value = true
    const {pending, data: dataLocal, error: errorLocal, refresh} = await client.user.list.useQuery({
      filters: unref(filters),
      pageInfo: {
        skip: Math.max(0, (currentPage.value - 1) * currentPageSize.value),
        take: currentPageSize.value
      }
    })
    data.value = dataLocal.value?.items || []
    total.value = dataLocal.value?.pageInfo.totalCount || 0
    error.value = errorLocal.value
    isLoading.value = false
  }

  watch(
    [currentPageSize, currentPage],
    () => {
      fetchData()
    },
    { immediate: true }
  )

  return {
    currentPageSize,
    currentPage,
    error,
    filters,
    data,
    isLoading,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    refresh: fetchData,
  }
}


export const useNotifications = async () => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.notification.list.query({
      filters: unref(filters),
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()


  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  return {
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useRequestedServices = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.requestedService.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()


  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useRequestedClientServices = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.requestedClientService.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()


  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}
export const useI18nList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.i18n.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}
export const useContentCompanyList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.contentCompany.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useContentCarsList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.contentCars.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}
export const useContentFaqList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.faq.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}
export const useContentReviewsList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.reviews.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}
export const useInboxList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.inbox.getMyMessages.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useFinesList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.fines.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useShipmentRequestList = async (filterInput) => {
  const client = useClient()

  const filters = ref({})
  const data: any = ref([])
  const total = ref(0)
  const isLoading = ref(false)

  const {
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
  } = useOffsetPagination({
    total,
    page: 1,
    pageSize: 10,
    onPageChange: fetchData,
    onPageSizeChange: fetchData,
  });

  async function fetchData(input = {}) {
    isLoading.value = true
    const {currentPage = 0, currentPageSize = 10} = input
    const result = await client.shipmentRequest.list.query({
      filters: {
        ...filterInput,
        ...unref(filters),
      },
      pageInfo: {
        skip: Math.max(0, (currentPage - 1) * currentPageSize),
        take: currentPageSize || 10
      }
    })

    data.value = result.items
    total.value = result.pageInfo.totalCount

    isLoading.value = false
  }

  await fetchData()

  watch(filters, async () => {
    await fetchData({
      // currentPage: currentPage.value,
      // currentPageSize: currentPageSize.value,
      filters: filters.value
    })
  }, {deep: true})

  const refresh = async () => {
    await fetchData({
      filters: filters.value
    })
  }
  return {
    refresh,
    filters,
    data,
    isLoading,
    currentPage,
    currentPageSize,
    pageCount,
    isFirstPage,
    isLastPage,
    prev,
    next,
    total,
  }
}

export const useRequestedService = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.requestedService.item.useQuery(input, {})

  const refresh = async () => {
    result = client.requestedService.item.useQuery(input, {});
  }
  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

export const useRequestedClientService = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.requestedClientService.item.useQuery(input, {})

  const refresh = async () => {
    result = client.requestedClientService.item.useQuery(input, {});
  }

  const {data, error, pending} = result
  return [data, error, pending, refresh]
}


//@ts-ignore
export const useCar = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.car.item.useQuery(input, {})

  const refresh = async () => {
    result = client.car.item.useQuery(input, {});
  }
  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

export const useCarFieldAutocomplete = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.car.fieldAutocomplete.useQuery(input, {})

  const refresh = async () => {
    result = client.car.fieldAutocomplete.useQuery(input, {});
  }

  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

export const useInboxFieldAutocomplete = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.inbox.autocomplete.useQuery(input, {})

  const refresh = async () => {
    result = client.inbox.autocomplete.useQuery(input, {});
  }

  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

export const useDocumentAudits = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.documentAudits.getItem.useQuery(input, {})

  const refresh = async () => {
    result = client.documentAudits.getItem.useQuery(input, {})
  }
  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

export const useDocumentAuditsAutocomplete = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.documentAudits.autocomplete.useQuery(input, {})

  const refresh = async () => {
    result = client.documentAudits.autocomplete.useQuery(input, {})
  }

  const {data, error, pending} = result
  return [data, error, pending, refresh]
}
export const useClientFieldAutocomplete = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.client.fieldAutocomplete.useQuery(input, {})

  const refresh = async () => {
    result = client.client.fieldAutocomplete.useQuery(input, {});
  }

  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

export const useServiceFieldAutocomplete = (filter) => {
  const client = useClient()

  const input = {...filter};
  let result = client.service.fieldAutocomplete.useQuery(input, {})

  const refresh = async () => {
    result = client.service.fieldAutocomplete.useQuery(input, {});
  }

  const {data, error, pending} = result
  return [data, error, pending, refresh]
}

//@ts-ignore
export const useCalculator = (input) => {
  const client = useClient()
  // @ts-ignore
  const {data, error, pending} = client.shippingPrice.list.useQuery(input, {})
  return [data, error, pending]
}

//@ts-ignore
export const useStages = (input) => {
  const client = useClient()
  // @ts-ignore
  const {data, error, pending} = client.stage.list.useQuery(input, {})
  return [data, error, pending]
}

export const useStates = (input) => {
  const client = useClient()
  // @ts-ignore
  const {data, error, pending} = client.car.states.useQuery(input, {})
  return [data, error, pending]
}
